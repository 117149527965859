.fieldWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 20px 5px 0;
    .fieldValue {
        display: flex;
        flex-flow: row wrap;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #666666;
    }
    .fieldName {
        display: flex;
        flex-flow: row wrap;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #A1A1A1;
        margin-right: 8px;
    }
}

.fieldsWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: max-content;
}

.isActive {
    background-position: center;
    height: 20px;
    width: 20px;
    background-image: url("./../../assets/isActive.svg");
    background-repeat: no-repeat;
}

