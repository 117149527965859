.breadcrumbs {
    color: #A1A1A1;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    max-width: 100%;

    a.link {
        .title {
            max-width: 50vw;
            overflow-x: auto;
            white-space: nowrap;
        }
        color: #A1A1A1;
        transition: all ease-out .2s;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding-right: 8px;
        .img {
            padding-left: 8px;
        }
        &:hover {
            color: #333333;
            text-decoration: underline;
            transition: all ease-out .2s;
        }
    }
}
