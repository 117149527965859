.mainContainer {
    width: 562px;
    height: 427px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background: #616875;
    border-radius: 4px;
}
.mainLabel {
    margin-top: 47px;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
}
.secondLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
}
.typeLoginPassLabel {
    color: rgba(255, 255, 255, 0.37);
    font-weight: normal;
    font-size: 16px;
    background-image: url("./../assets/arrow.svg");
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 20px;
    margin-left: 15px;
    padding-top: 15px;
    height: 53px;
}
.copImage {
    display: inline-table;
    background-image: url("./../assets/firstCop.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 106px;
    width: 560px;
    margin-top: 40px;
    &.error {
        background-image: url("./../assets/errorCop.svg");
    }
    &.full {
        background-image: url("./../assets/goodCop.svg");
    }
}
.loginContainer {
    width: 408px;
    margin: 0 auto;
}
.loginBox {
    text-align: center;
    border: unset;
    width: 408px;
    height: 44px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 5px;
    &::placeholder {
        color: rgba(255, 255, 255, 0.37);
    }
    &:hover {
        background: rgba(0, 0, 0, 0.3);
    }
    &:focus {
        background-color: #FFFFFF;
    }
    &.error {
        color: #FF6B6B;
    }
    &:required {
        background-color: #FFFFFF;
    }
    &.full {
        background-color: #FFFFFF;
    }

}
.errorLabel {
    font-weight: normal;
    font-size: 16px;
    color: #FF6B6B;
    text-align: center;
    height: 35px;
    padding-top: 5px;
}
.forgot {
    font-size: 16px;
    text-align: right;
    float: right;
    color: rgba(255, 255, 255, 0.37);
    border-bottom: 1px dashed rgba(255, 255, 255, 0.37);
}
.submit {
    background: #EFB82A;
    border-radius: 4px;
    width: 174px;
    height: 47px;
    margin-left: 197px;
    margin-top: 40px;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    &:disabled {
        background: rgba(239, 184, 42, 0.5);
        color: rgba(0, 0, 0, 0.37);
    }
}
.inputContainer {
    position: relative;
}
.passwordControl {
    all: unset;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 25px;
    height: 20px;
    background-image: url("./../assets/greyEye.svg");
    background-repeat: no-repeat;
    cursor: pointer;
    &.passwordControl.view {
        background-image: url("./../assets/blueEye.svg");
    }
}

