.mainContainer {

}
.halt-screen {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    min-height: 250px;
    color: #ff0800;
    text-align: center;
    vertical-align: middle;
    &:before {
        display: inline-block;
        content: '';
        height: 100%;
        vertical-align: middle;
        position: relative;
        width: 1px;
        padding-top: 66%;
    }
    span {
        display: inline-block;
        vertical-align: middle;
    }
}
