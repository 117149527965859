.hlpInline {
  display: inline-block;
}
.hlpRemoveIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  //background: url('../assets/remove.svg') no-repeat center center;
  background-size: cover;
}
.hlpAddIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  //background: url('../assets/add.svg') no-repeat center center;
  background-size: cover;
}
.hlpAddIcon--big {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: middle;
  //background: url('../assets/add--btn--big.png') no-repeat center center;
  background-size: cover;
}
.hlpCheckbox {
  width: 1em;
  height: 1em;
  margin-bottom: 0.2em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  &.is_on {
    &:after {
      //background: url('../assets/checked-checkbox.png') no-repeat center center;
      background-size: contain;
    }
  }
  &:before {
    content: '';
    color: transparent;
    border: 1px solid #bec3c3;
    display: block;
    width: 74%;
    height: 74%;
    box-sizing: content-box;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    left: 10%;
    z-index: 1;
  }
  &:after {
    content: '';
    color: transparent;
    display: block;
    width: 100%;
    height: 0%;
    padding-top: 100%;
    box-sizing: content-box;
    z-index: 6;
    position: relative;
  }
}

.hlpSelectIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  //background: url('../assets/select-icon.png') no-repeat center center;
  background-size: cover;
}
.hlpMarginBottom {
    margin-bottom: 1em;
}
