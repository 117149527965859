.navBarLine {
    display: inline-flex;
    white-space: nowrap;
    width: 100%;
    padding-left: 90px;
    background: rgba(0, 0, 0, 0.07);
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    height: 52px;
    align-items: stretch;
    justify-content: space-between;
    .leftItems {
        display: flex;
        flex-grow: 1;
        & > a {
            text-decoration: none;
        }
        .title {
            color: #878684;
            transition: all .2s ease-out;
            display: flex;
            flex-flow: column wrap;
            align-self: stretch;
            justify-self: stretch;
            align-items: center;
            margin-right: 33px;
            cursor: pointer;
            position: relative;
            .text {
                display: flex;
                justify-self: stretch;
                flex-grow: 1;
                height: 100%;
                align-items: center;
            }
            .underline {
                height: 3px;
                width: 100%;
                transition: all .2s ease-out;
                position: absolute;
                bottom: 0;
                background: transparent;
            }
            &.disabled {
                color: rgba(0, 0, 0, 0.4);
                cursor: auto;
            }
            &.active, &:hover {
                transition: all .2s ease-out;
                color: #2D2D2C;
                .underline {
                    background: #4F7EEE;
                }
                &.disabled {
                    color: rgba(0, 0, 0, 0.4);
                    .underline {
                        background: transparent;
                    }
                }
            }
        }
    }
    .rightItems {
        display: flex;
    }
}
