.container {
    display: inline-flex;
    margin-bottom: 30px;
    line-height: 20px;
    white-space: nowrap;
}
.label {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.37);
    margin-right: 20px;
}
.content {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.65);
    align-self: center;
    margin-left: 5px;
}
.active {
    background-image: url("../../assets/checked.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 14px;
    margin-left: 10px;
    margin-top: 3px;
}
.inActive {
    background-image: url("../../assets/minus.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 14px;
    margin-left: 10px;
    margin-top: 3px;
}
