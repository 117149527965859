.navigation {
    display: inline-flex;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.07);
    height: 52px;
    width: 100%;
    padding-left: 50px;
    white-space: nowrap;

}
.inactive {
    margin-right: 35px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 52px;
    height: 52px;
    &:hover {
        border: none;
        margin-right: 35px;
        height: 52px;
        line-height: 52px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8);
    }
}
.active {
    margin-right: 35px;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 2px solid #4F7EEE;
    line-height: 52px;
    height: 52px;
    &:hover {
        border: none;
        margin-right: 35px;
        color: rgba(0, 0, 0, 0.8);
        height: 50px;
        line-height: 52px;
        cursor: pointer;
        border-bottom: 2px solid #4F7EEE;
    }
}
