.wrapCommonInfo {
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    justify-content: stretch;
    .headerCommon {
        display: flex;
        flex-flow: column wrap;
    }
    .bodyCommon {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        padding-bottom: 250px;
    }
}
