@keyframes loader {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
}

.pagerContainer {
    z-index: 9;
    display: none;
    position: fixed;
    top: 0; left: 0;
    right: 0; bottom: 0;
    .layout {
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 0 0 4px 4px;
        opacity: 0.85;
        position: fixed;
        top: 0; left: 0;
        right: 0; bottom: 0;
    }

    .loader div { box-sizing: border-box!important }
    .loader > div {
        position: absolute;
        width: 134px;
        height: 134px;
        top: 33px;
        left: 33px;
        border-radius: 50%;
        border: 6px solid #000;
        border-color: #4f7eee transparent #4f7eee transparent;
        animation: loader 1.5873015873015872s linear infinite;
    }
    .loader > div:nth-child(2), .loader > div:nth-child(4) {
        width: 118px;
        height: 118px;
        top: 41px;
        left: 41px;
        animation: loader 1.5873015873015872s linear infinite reverse;
    }
    .loader > div:nth-child(2) {
        border-color: transparent #efb82a transparent #efb82a
    }
    .loader > div:nth-child(3) { border-color: transparent }
    .loader > div:nth-child(3) div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
    }
    .loader > div:nth-child(3) div:before, .loader > div:nth-child(3) div:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        top: -6px;
        left: 58px;
        background: #4f7eee;
        border-radius: 50%;
        box-shadow: 0 128px 0 0 #4f7eee;
    }
    .loader > div:nth-child(3) div:after {
        left: -6px;
        top: 58px;
        box-shadow: 128px 0 0 0 #4f7eee;
    }
    ​
    .loader > div:nth-child(4) { border-color: transparent; }
    .loader > div:nth-child(4) div {
        position: fixed;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
    }
    .loader > div:nth-child(4) div:before, .loader > div:nth-child(4) div:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        top: -6px;
        left: 50px;
        background: #efb82a;
        border-radius: 50%;
        box-shadow: 0 112px 0 0 #efb82a;
    }
    .loader > div:nth-child(4) div:after {
        left: -6px;
        top: 50px;
        box-shadow: 112px 0 0 0 #efb82a;
    }
    .spinnerDoubleRing {
        width: 200px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        background: none;
        top: 35%;
        left: 51%;
        position: absolute;
    }
    .loader {
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
    }
    .loader div { box-sizing: content-box; }
&.on{
    display: block;
}
}

