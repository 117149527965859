.mainContainer {
position: relative;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
}
.search {
    padding-bottom: 1em;
    display: block;

    .value {
        display: flex;
        vertical-align: top;
        padding-right: 1em;
        position: relative;

        .label {
            font-weight: 600;
        }

        .reasonSelector {
            cursor: default;
        }

    }

}

.multiselect {
    border: solid 1px;

    width: auto;
    padding: 1em;

    > div {
        white-space: nowrap;

        label {
            display: block;
        }
    }
}

.topLabelCompany {

    border-style: none;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    height: 70px;
    width: auto;
    line-height: 60px;
    color: rgba(0, 0, 0, 0.32);
    background-color: white;
}

.eventsLabel {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 30px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
}

.smallSelector {

    font-size: 1px;
    color: transparent;
    border: none;
    width: 17px;
    height: 17px;
    background-image: url("./assets/filter.png");
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 5px;
    float: left;
}

.filtersBar {

    float: top;
}

.options {
    color: yellow;
}

.messageButton {
    background-image: url("./assets/message.png");
    background-repeat: no-repeat;
    height: 50px;
}

.tableContainer {
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;

}

.filterActive {
    display: flex;
    background: #F7F7F3;
    border: 1px solid #AFB3BA;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.65);
    margin-right: 10px;
}

.delivered {
    color: #149938;
}
.noBalance {
    color: #DB6A00;
}


.dateSearchContainer {

}
.chromePic {
    width: 18px;
    background-image: url("./assets/chrome.png");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 3px;
}
.deviceContainer {
    display: flex;
    text-transform: none;
}
.androidPic {
    width: 18px;
    background-image: url("./assets/android.png");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 3px;
}
.applePic {
    width: 18px;
    background-image: url("./assets/apple.png");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 3px;
}

.dropdown {
    margin-top: -2px;
    margin-left: 5px;
    height: 20px;
    line-height: 20px;
    position: relative;
    display: inline-block;
}

.dropdownContent {
    display: none;
    position: absolute;
    min-width: 160px;
    background: #FFFFFF;
    box-shadow: 0 4px 5px rgba(63, 63, 63, 0.2), 0 2px 14px rgba(121, 121, 121, 0.12), 0 8px 10px rgba(121, 121, 121, 0.14);
    border-radius: 4px;
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropdownContent a {
    padding: 3px 3px 3px 3px;
    text-decoration: none;
    display: block;
    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    &.empty {
        background-color: transparent;
        content: '';
    }
}

.dropdownContent a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition-delay: 0s;
    transition-duration: 0.5s;
}

.dropdown:hover .dropdownContent {
    display: block;
    transition-delay: 2s;
    transition-duration: 2s;

}

.dropdown:hover .dropButton {
    background: rgba(0, 0, 0, 0.1);
    transition-delay: 2s;
    transition-duration: 2s;

}
.dropDownImage {
    margin-left: 15px;
    margin-right: 15px;
}
.smallSubMenu {
    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}
.eventsContainer {
    height: auto;
    width: 100%;
}

.tableMainContainer {
    width: 100%;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15), 0 2px 3px rgba(14, 13, 9, 0.08), 0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;
}
.pagerContainer {
    margin-top: 20px;
    height: 50px;
}
.noEvents {
    text-align: center;
}
