.content {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 0;
    background-color: #F2F1ED;

}
.container {
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex: auto;
    width: 100%;
    height: 100%;
    background-color: #F2F1ED;

}

