.headerInMainBody {
    margin-left: 90px;
    margin-right: 3em;
    min-height: 96px;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    padding: 27px 0;
    &.marginLeft80 {
        margin-left: 80px;
    }
    .headerMain {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .title {
            color: #333333;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            padding-right: 8px;
            max-width: 50vw;
            overflow-x: auto;
            white-space: nowrap;
        }
        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 12px;
            color: #666666;
            background: #FAD796;
            border-radius: 20px;
        }
    }
    .description {
        color: #A1A1A1;
        display: flex;
        flex-flow: row wrap;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        padding-top: 13px;
    }
    &.flexStart {
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
    }
    &.descriptionFontSize24 {
        .description {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
        }
    }
    &.descriptionPadding0 {
        .description {
            padding: 0;
        }
    }
}

