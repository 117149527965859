.containerPad {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}
.headerContainer {
    padding-left: 50px;
}
.searcher {
    width: 560px;
    height: 45px;
    float: left;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 5px;
}
.searchButton {
    margin-left: 10px;
    width: 174px;
    min-width: 174px;
    background: #4F7EEE;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    &:hover{
        cursor: pointer;
    }
}
.center {
    text-align: center;
}
.clearAllButton {
    width: 148px;
    color: #1D55D7;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 45px;
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 20px;
    display: none;
    &.active {
        display: block;
        color: #1D55D7;
    }
}
.clearAllButtonContainer {
    display: block;
    height: 45px;
    width: 100%;
}
.inputSearcher{
    border: none;
    height: 45px;
    width: 350px;
    margin-left: 20px;
    &::placeholder{
        color: rgba(0, 0, 0, 0.37);
        font-size: 16px;
    }
}
.mainLabel {
    font-weight: 600;
    font-size: 24px;
    line-height: 91px;
    font-style: normal;
    color: rgba(0, 0, 0, 0.37);
}
.clientLabel{
    font-weight: 600;
    font-size: 24px;
    line-height: 91px;
    font-style: normal;
    color: rgba(0, 0, 0, 0.8);
}
.leftDiv {
    float: left;
    display: flex;
}
.rightDiv {
    margin-left: auto;
    display: flex;
    float: right;
}

.labelDiv {
    display: flex;
    height: 91px ;
    margin-top: -10px;
}
.mainContent {
    float: left;
    min-width: 800px;
}
.mainTable {
    background-color: white;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;
    margin-bottom: 30px;
}
.mainTr {
    white-space: nowrap;
    height: 60px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.32);


}
.id {
    width: 7%;
    padding-left: 40px;


}
.dateTime {
    white-space: nowrap;
    width: 15%;
    padding-left: 40px;
}
.type {
    width: 8%;
    padding-left: 40px;
}
.duration {
    width: 10%;
    padding-left: 40px;
    text-align: right;
}
.price {
    text-align: right;
    white-space: nowrap;
    width: 9%;
    padding-left: 40px;
    padding-right: 40px;
}
.company {
    padding-right: 40px;
    white-space: nowrap;
}
.trLine {
    border-top: 1px solid rgba(14, 13, 9, 0.08);
    width: 100%;
    margin-top: 5px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    &:hover {
        background-color: #FFFBF0;
        cursor: pointer;
        }
    }

.black {
    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.filter {
    display: inline-flex;
    margin-top: 30px;
    height: auto;
}
.filterInput {
    margin-right: 10px;
    width: 205px;
    height: 45px;
    background: #FFFFFF;
   box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
   0 2px 3px rgba(14, 13, 9, 0.08),
   0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;
        .inputClass {
            border: none;
            margin-left: 20px;
            height: 45px;
            &::placeholder {
                color: rgba(0, 0, 0, 0.37);
                font-size: 16px;
            }
        }
}
.calendarLeft {
    padding-left: 15px;
    background: #FFFFFF;
    border-radius: 4px 0 0 4px;
    width: 155px;
    height: 45px;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
}
.calendarRight {
    padding-left: 15px;
    background: #FFFFFF;
    border-radius: 0 4px 4px 0;
    width: 155px;
    height: 45px;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
    margin-right: 10px;
}
.dateTimePicker {
    line-height: 42px;
    text-align: left;
    margin-left: 10px;
    border-style: none;
    width: 110px

}
.arrowRight {
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('../../assets/arrowRight.png');
    width: 8px;
    line-height: 89px;
    margin-top: 4px;
    margin-left: 15px;
    margin-right: 10px;
}

.modal {
    position: fixed;
    margin-left: 300px;
    width: 100%;
    height: 109%;
    left: 0;
    top: 0;
    background-color: #6D6F73;
    opacity: 0.6;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
    border-radius: 4px;
    z-index: 1;

}
.modalContent {
    position: fixed;
    left: 20%;
    top: 2%;
    z-index: 2;
    margin-top: 50px;
    margin-left: 85px;
    background-color: white;
    opacity: 1.2;
    width: 59.3%;
    min-width: 830px;
    height: 900px;
    animation-duration: 0.5s;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
}

.close {
    font-size:  30px;
    font-weight:  bold;
}
.modalHeader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0 0;
    height: 86px;
    line-height: 86px;
}
.closeButton {
    margin-right: 15px;
    margin-top: 28px;
    float: right;
    background-image: url('../../assets/closeButton.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.modalLabel {
    float: left;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin-left: 20px;
}
.modalBody {

    background-color: white;
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.modalLine {
    width: 100%;
    min-height: 40px;
    display: flex;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.modalLeft {
    display: flex;
    white-space: nowrap;
    vertical-align: center;
    width: 140px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.32);
}
.modalRight {
    max-width: 650px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 20px ;
    vertical-align: center;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
}
.modalRightServices {
    max-width: 650px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 20px ;
    vertical-align: center;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    height: auto;
}
.eventsTotal {
    width: 100%;
    display: block;
    line-height: 44px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.32);
}
.modalEvents {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
}
.blackCard {
    float: left;
   height: 160px;
    width: 368px;
    background-color: #6D6F73;
    opacity: 0.7;
    margin-top: 20px;
    box-shadow: 0 1px 2px rgba(14, 13, 9, 0.15),
    0 2px 3px rgba(14, 13, 9, 0.08),
    0 5px 4px rgba(14, 13, 9, 0.06);
}
.dropdown {
    margin-left: 5px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    position: relative;
    display: inline-block;
    background-image: url("./../../assets/filter.png");
    background-repeat: no-repeat;
    background-position: bottom;
    &.active{
        background-image: url("./../../assets/filterBlue.svg");
    }
    &.checked {
        background-image: url("./../../assets/filterBlue.svg");
    }
}

.dropdownContent {
    text-align: left;
    display: none;
    position: absolute;
    min-width: 160px;
    background: #FFFFFF;
    box-shadow: 0 4px 5px rgba(63, 63, 63, 0.2), 0 2px 14px rgba(121, 121, 121, 0.12), 0 8px 10px rgba(121, 121, 121, 0.14);
    border-radius: 4px;
    padding-top: 4px;
    z-index: 1;
}
.dropdown:hover .dropdownContent {
    display: block;
}
.dropdownContent span {
    text-transform: none;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    display: block;
    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 47px;
    height: 47px;
    cursor: default;
    &.empty {
        background-color: transparent;
        content: '';
    }
    &.checked {
        text-wrap: none;
        background-position: 18px ;
        background-repeat: no-repeat;
        height: 47px;
        line-height: 47px;
        padding-left: 40px;
        background-image: url('../../assets/checked.png');
    }

}

.dropdownContent span:hover {
    background-color: #F0F6FE;
    cursor: default;
    transition-delay: 0s;
    transition-duration: 0.3s;
}

.inactive {
    border: none;
    color: rgba(0, 0, 0, 0.37);
    &:hover {
        border: none;
        cursor: pointer;
    }
}
.active {
    border: none;
    color: rgba(0, 0, 0, 0.37);
    &:hover {
        border: none;
        cursor: pointer;
    }
}
.clickArea {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    cursor: default;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
    &.show {
        display: block;
    }
}
