.active {
    background: #46484d;
    color: #FFFFFF ;
}
.headerLayout {
    width: 300px;
    position: fixed;
    height: 100%;
    background-color: #323439;
    z-index: 99;
    &.small {
        width: 169px;
    }
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.2),
    1px 0 18px rgba(88, 88, 88, 0.12), 6px 0 10px rgba(88, 88, 88, 0.14);
}
.header {
    z-index: 101;
    flex: 0 0 300px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    background-color: #323439;
    min-height: 400px;
    height: auto;
    overflow-y: auto;
    color: #fff;
    &.small {
        z-index: 101;
        width: 169px;
        min-width: 169px;
        flex: 0 0 169px;
    }
}

.logo {
    display: block;
    margin-left: 0;
    cursor: pointer;
    color: #EFB82A;
    height: 100px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.appName {
    position: fixed;
    border-style: none;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    padding: 30px;
    color: #EFB82A;
    background-color: #323439;
    &:hover {
        cursor: pointer;
    }
    &.small {
        width: 169px;
        padding: 20px 0 0 0;
        height: 93px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        text-align: center;
    }
}

.navigation-default.a.active {
    text-align: left;
    width: 300px;
    list-style: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.navigation {
    display: inline-block;
    margin-top: 92px;
    // overflow-y: auto;
    overflow-y: hidden;
    text-align: left;
    width: 300px;
    max-height: 75%;
    height: 70%;
    list-style: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.2s ease-out;
    &.small {
        width: 169px;
        padding-top: 60px;
        background-color: #323439;
        height: 100%;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
    }
    &:hover, &:focus {
        overflow-y: auto;
        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.16);
        }
        &::-webkit-scrollbar-thumb:hover {
            width: 6px;
        }
    }
}

.userNav {
    all: unset;
    width: 300px;
    position: fixed;
    left:0; bottom: 0;
    height:80px;
    line-height: 80px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.4);
    background-color: #2B2C30;
    cursor: pointer;
    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
}
.userNavSmall {
    all: unset;
    width: 169px;
    position: fixed;
    left:0; bottom: 0;
    height:112px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.4);
    background: #2B2C30;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.8);
    }
}

.buttonsContainer{
    display: flex;
    position: fixed;
    left:0;
    bottom: 80px;
}
.buttonsContainerSmall{
    position: fixed;
    left:0;
    bottom: 120px;
    width: 169px;
}

.avatar {
    float: left;
    height: 80px;
    width: 40px;
    background-image: url(./assets/avatar.svg);
    background-position: center;
    background-repeat: no-repeat;
    color: rgba(255, 255, 255, 0.4);
    padding-left: 40px;
    &.small {
        padding-left: 120px;
    }
}

.logout {
    float: left;
    background-color: #2B2C30;
    color:  #FFFFFF;
    text-align: left;
    padding-left: 20px;
    margin-left: 8px;
    margin-bottom: 8px;
    width: 117px;
    height: 46px;
    border: none;
    outline: none;
    align-items: center;
    cursor: pointer;
    background-image: url(./assets/logout.svg);
    background-repeat: no-repeat;
    background-position: 80px 10px;
    border-radius: 4px;

}
.logoutSet {
    float: left;
    background-color: #2B2C30;
    color:  #FFFFFF;
    text-align: left;
    padding-left: 20px;
    margin-left: 8px;
    width: 156px;
    height: 46px;
    border: none;
    outline: none;
    align-items: center;
    cursor: pointer;
    background-image: url(./assets/logoutSet.svg);
    background-repeat: no-repeat;
    background-position: 110px 10px;
    border-radius: 4px;
}
.logNav {
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.icon {
    padding-left: 20px;
    height: 50px;
    width: 30px;
    background-position-x: 25px;
    background-position-y: 12px;
    left: 25px;
    background-repeat: no-repeat;

    &.catserv {
        background-image: url(./assets/catalougeServices.svg);
        &:hover {
            background-image: url(./assets/catalougeServicesWhite.svg);
        }
    }

    &.serv {
        background-image: url(./assets/services-icon.svg);
        &:hover {
            background-image: url(./assets/servicesWhite.svg);
        }
    }

    &.drums {
        background-image: url(./assets/drums.svg);
        &:hover {
            background-image: url(./assets/drumsWhite.svg);
        }
    }

    &.packets {
        background-image: url(./assets/packets.svg);
        &:hover {
            background-image: url(./assets/packetsWhite.svg);
        }
    }

    &.companies {
        background-image: url(./assets/companies.svg);
        &:hover {
            background-image: url(./assets/companiesWhite.svg);
        }
    }

    &.users {
        background-image: url(./assets/Users.svg);
        &:hover {
            background-image: url(./assets/usersWhite.svg);
        }
    }

    &.clients {
        background-image: url(./assets/Clients.svg);
        &:hover {
            background-image: url(./assets/clientsWhite.svg);
        }
    }

    &.notifications {
        background-image: url(./assets/notifications.svg);
        &:hover {
            background-image: url(./assets/notificationsWhite.svg);
        }
    }
    &.instructions {
        background-image: url(./assets/instructions.svg);
        &:hover {
            background-image: url(./assets/instructionsWhite.svg);
        }
    }
    &.references {
        background-image: url(./assets/references.svg);
        padding-left: 20px;
        &:hover {
            background-image: url(./assets/referencesWhite.svg);
        }
    }
    &.expenses {
        background-image: url(./assets/expenses.svg);
        &:hover {
            background-image: url(./assets/expensesWhite.svg);
        }
    }
    &.metrics {
        background-image: url(./assets/metrics.svg);
        &:hover {
            background-image: url(./assets/metricsWhite.svg);
        }
    }
    &.carGallery {
        background-image: url(./assets/carGallery.svg);
        &:hover {
            background-image: url(./assets/carGalleryWhite.svg);
        }
    }

    &.statistic {
        background-image: url(./assets/statistic.svg);
        &:hover {
            background-image: url(./assets/statisticWhite.svg);
        }
    }

    &.balance {
        background-image: url(./assets/balance.svg);
        &:hover {
            background-image: url(./assets/balanceWhite.svg);
        }
    }

    &.send {
        background-image: url(./assets/send.svg);
        &:hover {
            background-image: url(./assets/ortusSendWhite.svg);
        }
    }
}
.navActive {
    padding-left: 20px;
    height: 50px;
    width: 30px;
    background-position-x: 25px;
    background-position-y: 12px;
    left: 25px;
    background-repeat: no-repeat;
    background-color: #46484d;
    text-decoration: none;
    color: #FFFFFF;
    border-left: 2px solid #EFB82A;

    &.catservActive {
        background-image: url(./assets/catalougeServicesWhite.svg);
        color: #FFFFFF;
    }

    &.servActive {
        background-image: url(./assets/servicesWhite.svg);
        color: #FFFFFF;
    }

    &.drumsActive {
        background-image: url(./assets/drumsWhite.svg);
        color: #FFFFFF;
    }

    &.packetsActive {
        background-image: url(./assets/packetsWhite.svg);
        color: #FFFFFF;
    }

    &.companiesActive {
        background-image: url(./assets/companiesWhite.svg);
        color: #FFFFFF;
    }

    &.usersActive {
        background-image: url(./assets/usersWhite.svg);
        color: #FFFFFF;
    }

    &.clientsActive {
        background-image: url(./assets/clientsWhite.svg);
        color: #FFFFFF;
    }

    &.notificationsActive {
        background-image: url(./assets/notificationsWhite.svg);
        color: #FFFFFF;
    }

    &.instructionsActive {
        background-image: url(./assets/instructionsWhite.svg);
        color: #FFFFFF;
    }

    &.referencesActive {
        background-image: url(./assets/referencesWhite.svg);
        color: #FFFFFF;
    }

    &.expensesActive {
        background-image: url(./assets/expensesWhite.svg);
        color: #FFFFFF;
    }

    &.metricsActive {
        background-image: url(./assets/metricsWhite.svg);
        color: #FFFFFF;
    }

    &.carGalleryActive {
        background-image: url(./assets/carGalleryWhite.svg);
        color: #FFFFFF;
    }

    &.statisticActive {
        background-image: url(./assets/statisticWhite.svg);
        color: #FFFFFF;
    }

    &.balanceActive {
        background-image: url(./assets/balanceWhite.svg);
        color: #FFFFFF;
    }

    &.sendActive {
        background-image: url(./assets/ortusSendWhite.svg);
        color: #FFFFFF;
    }
}
.navList {
    height: 50px;
    line-height: 50px;
    width: 100%;
    display: flex;
    padding-top: 3px;
    color: rgba(255, 255, 255, 0.4);
    }

.navListSmall {
    height: 50px;
    line-height: 50px;
    width: 100%;
    display: flex;
    padding-top: 3px;
    padding-left: 20px;
    color: rgba(255, 255, 255, 0.4);
    &:hover {
        text-decoration: none;
        color: #FFFFFF ;
    }
    }

.navList:hover {
    text-decoration: none;
    color: #FFFFFF ;
}
.navLabel {
    margin-left: 50px;
    letter-spacing: 0.05em;
    &.small {
        margin-left: 0;
        letter-spacing: 0.05em;
        color: rgba(255, 255, 255, 0.4)
    }
}

.balanceTop {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 25px;

    .balanceList {
        font-size: 14px;
        line-height: 19px;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
        margin-left: 65px;
        padding-top: 10px;
    }
}
.navListText {

}
.blackBack {
    height: 80px;
    display: block;
    bottom: 0;
    position: relative;
}


